<template>
	<div data-route>
		<page-header
			heading="Find a course"
		/>
		<div data-element="main">
			<card-view
				:cards="getCards"
				:filters="getFilters"
				:pagination="pagination"
				:totalItems="totalItems"
				cardType="course"
				searchPlaceholder="Search by course name…"
				@search="onSearch"
				@filter="onFilter"
				@paginate="onPaginate"
			/>
		</div>
	</div>
</template>

<route>
	{
		"meta": {
			"isPublic": true
		}
	}
</route>

<script>

	import PageHeader            from '@/components/ui/PageHeader';
	import CardView              from '@/components/ui/views/cardView/CardView';
	import api                   from '@/services/api';
	import { experienceLevels }  from '@/consts';
	import { biddingSystems   }  from '@/consts';

	export default {
		metaInfo: {
			title: 'Find a course'
		},
		components: {
			PageHeader,
			CardView
		},
		data: () => ({
			courses:        [],
			focus:          [],
			keywords:       [],
			allKeywords:    [],
			teachers:       [],
			searchString:   '',
			activeFilters:  {},
			pagination: {
				perPage:      21,
				currentPage:  1,
				totalPages:   0
			},
			totalItems:     0
		}),
		computed: {
			getKeywords () {
				return this.keywords.flatMap((focus) => {
					return focus.keywords.map((keyword) => {
						return {
							text: keyword,
							value: keyword
						};
					});
				});
			},
			filterKeys () {
				if (!this.activeFilters.focus || this.activeFilters.focus.length === 0) {
					return this.allKeywords;
				}
				return this.activeFilters.focus.map((focus) => {
					return this.allKeywords.find(key => key.focus === focus);
				});
			},
			getUsersDefaultExperienceLevel () {
				if (!process.env.VUE_APP_ENABLE_DEFAULT_COURSE_FILTERS) {
					return undefined;
				}
				return this.$store.getters['auth/getUsersDefaultExperienceLevel'];
			},
			getUsersDefaultBiddingSystem () {
				if (!process.env.VUE_APP_ENABLE_DEFAULT_COURSE_FILTERS) {
					return undefined;
				}
				return this.$store.getters['auth/getUsersDefaultBiddingSystem'];
			},
			getFilters () {
				return [
					{
						text: 'Experience level',
						value: 'experienceLevel',
						type: 'experienceLevelRange',
						default: this.getUsersDefaultExperienceLevel !== undefined ? [
							this.getUsersDefaultExperienceLevel
						] : undefined,
						options: experienceLevels.slice(0, 8)
					},
					{
						text: 'Teacher',
						value: 'teacherName',
						options: this.teachers.map((teacher) => {
							return {
								text: teacher,
								value: teacher
							};
						})
					},
					{
						text: 'Bidding system',
						value: 'biddingSystems',
						default: this.getUsersDefaultBiddingSystem !== undefined ? [
							this.getUsersDefaultBiddingSystem
						] : undefined,
						options: biddingSystems.map((biddingSystem) => {
							return {
								text: biddingSystem.value,
								value: biddingSystem.value
							};
						})
					},
					{
						text: 'Focus',
						value: 'focus',
						options: this.focus.map((focus) => {
							return {
								text: focus.focus,
								value: focus.focus
							};
						})
					},
					{
						text: 'Keyword',
						value: 'keywords',
						options: [...new Map(this.getKeywords.map(item => [item.text, item])).values()]
					}
				];
			},
			getCourses () {
				if (!this.courses ||
						!this.courses.length) {
					return false;
				}
				return this.courses;
			},
			getCards () {
				if (!this.getCourses) {
					return [];
				};
				return this.getCourses.map((course) => {
					return {
						route: this.getCourseRoute(course),
						course
					};
				});
			},
			getBreadcrumbs () {
				return [
					{
						path: '/browse-courses',
						text: 'Find a course'
					}
				].filter(Boolean);
			}
		},
		created () {},
		methods: {
			onSearch (value) {
				this.searchString = value;
				this.setCourses();
			},
			onFilter (value) {
				this.activeFilters = value;
				this.pagination.currentPage = 1;
				this.setCourses();
			},
			onPaginate (value) {
				this.pagination.currentPage = value;
				this.setCourses();
			},
			getCourseRoute (course) {
				if (!this.$store.getters['auth/getIsAuthenticated']) {
					return '/create-account';
				}
				if (course?.activated) {
					return `/my-courses/${course.id}`;
				}
				return `/browse-courses/${course.id}`;
			},
			async setUnauthenticatedCourses () {
				return await api.courses.getPublishedPublicCourses({
					include: 'metadata,focus_keyword,only_linked_keywords,teachers,teacher_name',
					searchString: this.searchString,
					filters: this.activeFilters,
					page: this.pagination.currentPage,
					per: this.pagination.perPage,
					sortBy: 'min_experience_level',
					sortDir: 'ASC'
				});
			},
			async setAuthenticatedCourses () {
				return await api.courses.getPublishedCourses({
					include: 'metadata,focus_keyword,only_linked_keywords,teachers,teacher_name',
					searchString: this.searchString,
					filters: this.activeFilters,
					page: this.pagination.currentPage,
					per: this.pagination.perPage,
					sortBy: 'min_experience_level',
					sortDir: 'ASC'
				});
			},
			async setAuthenticatedOrUnauthenticatedCourses () {
				if (!this.$store.getters['auth/getIsAuthenticated']) {
					return await this.setUnauthenticatedCourses();
				}
				return await this.setAuthenticatedCourses();
			},
			async setCourses () {
				const response = await this.setAuthenticatedOrUnauthenticatedCourses();
				if (!response) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.focus = response.meta.focusKeyword;
				this.allKeywords = response.meta.focusKeyword;
				this.keywords = this.filterKeys;
				this.teachers = [...new Set(response.meta.teacherName)];
				this.pagination.totalPages = response.meta.numPages;
				this.pagination.currentPage = response.meta.page;
				this.totalItems = response.meta.totalItems;
				this.courses = response.results;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};

</script>
